#chat-wrapper{
    width: 345px;
    height: 470px;
    display: none;
    transition: transform 0.5s ease-in-out;
    transform: scale(0);
    transform-origin: bottom right;
}

#chat-wrapper.opened{
    display: block;
    transform: scale(1);
}

#chat-wrapper #cometchat__widget{
    width: 101%;
    height: 101%;
    margin: -1%;
}

#chat-wrapper .app__messenger{
    border: none;
}
