body,
html,
#root {
  font-size: 100%;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #F8F8F9;
  color: #2f2f2f; /*debilite black font color because pure black is too aggressive*/
}

.pdf canvas {
  width: 100% !important;
  height: auto !important;
}
.google-button div {
  background: none !important;
}
/* Spinner Loading */
#spinner {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #4d70b3;
}
#path-one {
  stroke-dasharray: 600px;
  stroke-dashoffset: 600px;
  animation: line-anim-white 5s ease infinite;
}
#path-two {
  stroke-dasharray: 379px;
  stroke-dashoffset: 379px;
  animation: line-anim-blue-dark 5s ease infinite;
}
#path-three {
  stroke-dasharray: 379px;
  stroke-dashoffset: 379px;
  animation: line-anim-blue-dark 5s ease infinite;
}
#path-four {
  stroke-dasharray: 379px;
  stroke-dashoffset: 379px;
  animation: line-anim-blue-light 5s ease infinite;
}
#path-five {
  stroke-dasharray: 379px;
  stroke-dashoffset: 379px;
  animation: line-anim-white 5s ease infinite;
}
#path-six {
  stroke-dasharray: 379px;
  stroke-dashoffset: 379px;
  animation: line-anim-blue-light 5s ease infinite;
}
#loading-text {
  margin-top: 2rem;
  font-size: 12px;
  font-weight: 400;
  animation: fadeIn ease infinite 5s;
}

@keyframes line-anim-white {
  50% {
    stroke-dashoffset: 0;
  }
  60% {
    stroke-dashoffset: 0;
  }
}

@keyframes line-anim-blue-light {
  50% {
    stroke-dashoffset: 0;
  }
  60% {
    stroke-dashoffset: 0;
  }
}

@keyframes line-anim-blue-dark {
  50% {
    stroke-dashoffset: 0;
  }
  60% {
    stroke-dashoffset: 0;
  }
}

@keyframes line-anim {
  50% {
    stroke-dashoffset: 0;
  }
  60% {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes appearFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.custom-circle {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.rc-slider-handle {
  border: solid 2px #4d70b3 !important;
}

.rc-slider-handle:active {
  box-shadow: 0 0 5px #4d70b3 !important;
}

.rc-slider-step:active {
  background: #4d70b3 !important;
}

.rc-slider-track {
  background: #4d70b3 !important;
}

.rc-slider-dot-active {
  border-color: #4d70b3 !important;
}

.layer-shadow {
  box-shadow: 0 0 45px 0 #a1b7e1;
}

.actions-wrapper .actions {
  display: none !important;
}

.actions-wrapper:hover .actions {
  display: block !important;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-color-swatch {
  border: 0;
  border-radius: 0;
}

::-moz-color-swatch,
::-moz-focus-inner {
  border: 0;
  @apply rounded-2xl;
}

::-moz-focus-inner {
  padding: 0;
}

.text-placeholder-gray {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(131 134 141 / var(--tw-placeholder-opacity)) !important;
}

.h-fit {
  height: fit-content;
}

.avatar-startup {
  object-fit: contain;
}

.info-card-container-img {
  height: 80px;
}


.tsc-account-type-img {
  background-size: 6em;
}

@media (min-width: 640px) {
  .tsc-account-type-img {
    background-size: contain;
  }
}

@media (min-width: 1280px) {
  .tsc-account-type-img {
    background-size: contain;
  }
}

