.sortable-choosen {
    transform: scale(1.02) rotate(-1deg);
    box-shadow: 0px 0px 20px rgba(134, 153, 159, .16);
    background-color: #F8F8F8;
}

.sortable-choosen-column {

}

.drag {
    position: absolute;
    opacity: 0;
    background-color: blue;
    z-index: 2000;
}
